import { Button, Form, Input, notification } from "antd";
import React, { useState, useEffect } from "react";
import LogoHook from "../logo";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../assets/utils/useAuth";
import lib from "../../assets/utils/lib";
import "./login.css";

import { useDispatch } from "react-redux";

import { add_user, selectUser } from "../../stores/user";
import { SyncOutlined } from "@ant-design/icons";
import CopyRight from "../compoinents/copywrite"
import { useForm } from "antd/lib/form/Form";

const LoginPageHook = () => {
  const dispatcher = useDispatch(selectUser);

  const [data, setData] = useState({});
  const { set, user } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const key = "login";

  useEffect(() => {
    if (user) {
      switch (Number(user?.role)) {
        case 1:
          navigate("/verify");
          break;
        case 2:
        case 3:
          navigate("/checkins");
          break;
        case 4:
          navigate("/dash");
          break;
        default:
          navigate("/dash");
          break;
        // case 1:
        // case 2:
        // case 3:
        //   navigate("/checkins");
        //   break;
        // case 4:
        //   navigate("/dash");
        //   break;
        // default:
        //   navigate("/dash");
      }
    }
  }, [navigate, user]);

  const logUserIn = async () => {
    const data = await form.validateFields();
    console.log(data)
    if (data && Object.keys(data).length < 2) {
      notification.error({
        message: "Missing Field",
        description: "make sure all the fields were appropriately filled",
        duration: 3,
        key,
      });
      return;
    }
    setLoading(true)
    notification.info({
      icon: <SyncOutlined spin size={"medium"} />,
      message: (
        <>
          <span>
            <strong>Logging in...</strong>
          </span>
          <br></br>
          <span>Please Wait</span>
        </>
      ),
      key,
    });

    let reqData = await lib.login(data);

    if (reqData?.data?.error) {
      notification.error({ message: reqData?.data?.message, description: reqData?.data?.description, duration: 2, key });
      setLoading(false);
    } else {
      if (Number(reqData?.data?.error) === 0) {
        notification.success({ message: reqData?.data?.message, description: reqData?.data?.description, duration: 3, key });
        dispatcher(add_user({ user: reqData?.data?.data }));
        set(reqData?.data?.data);
      } else {
        setLoading(false);
      }
    }
  };


  return (
    <>
      <div className="loginPage App-header">
        <Form form={form} onFinish={logUserIn} className="form">
          {/* <form> */}
          <LogoHook />
          <div class="input-group mb-4">
            <label htmlFor='fromExample1' style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', width: '100%' }} >Usename</label>
            <Form.Item required name="email" style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', width: '100%' }}>
              <Input
                type="email"
                id="form1Example1"
                // onChange={(e) =>
                //   setData((d) => ({ ...d, email: e.target.value }))
                // }
                onPressEnter={logUserIn}
              />
            </Form.Item>
          </div>

          <div class="input-group mb-4">
            <label htmlFor='fromExample1' style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', width: '100%' }} >Password</label>
            <Form.Item required style={{ color: 'black', fontWeight: 'bold', fontSize: '18px', width: '100%' }} name='password' >
              <Input.Password
                id="form1Example2"
                // onChange={(e) =>
                //   setData((d) => ({ ...d, password: e.target.value }))
                // }
                onPressEnter={logUserIn}
              />
            </Form.Item>
          </div>

          <Button
            type="submit"
            class="btn btn-primary btn-block"
            style={{ width: "100%" }}
            disabled={loading}
            loading={loading}
            htmlType="submit"
          >
            Login
          </Button>
          {/* </form> */}
          <CopyRight />
        </Form>
      </div>
    </>
  );
};

export default LoginPageHook;
