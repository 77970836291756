import axios from "axios";

export const isDev = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const uri = isDev()? 'https://v1.amanhmo.com/' : 'https://v1.amanhmo.com/';
export const uri_img = 'https://v1.amanhmo.com/';

const inDev = isDev();

export const header = {
  'Version': '1.5.1',
  'Application': 3,
  'Dev':  isDev()? 1 : 0
};

const Axios = axios.create({ baseURL: uri, headers: header });

export default Axios;
