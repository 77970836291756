import React from "react";
import logo from "../assets/images/claima_black.png";
import { useAuth } from "../assets/utils/useAuth";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu, message, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Helpers from "../assets/utils/Helpers";

import { useDispatch } from "react-redux";

const HeaderHook = () => {
  const { set, user } = useAuth();
  const navigate = useNavigate();
  const key = "nav";

  const dispatcher = useDispatch();

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "Logout",
          key: "1",
          icon: <UserOutlined />,
        },
      ]}
    />
  );

  function handleButtonClick(e) {  }

  function handleMenuClick(e) {
    if (Number(e.key) === 1) {
      message.info({ content: "Logging Out...", key });
      setTimeout(() => {
        Helpers.logout(set, dispatcher);
        message.info({ content: "Logged Out...", key, duration: 2 });
        navigate("/login");
        window.location.reload(true)
      }, 2000);
    }

  }

  return (
    <>
      <header>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <div class="container justify-content-betwween">
            <a class="navbar-brand me-2" href="./dashboard">
              <img src={logo} alt="Claima"  width={100} height={30}/>
            </a>

            <button
              class="navbar-toggler"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#navbarButtonsExample"
              aria-controls="navbarButtonsExample"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fas fa-bars"></i>
            </button>
            {user ? (
              <>
                <div
                  class="collapse navbar-collapse gap-5"
                  id="navbarButtonsExample"
                  style={{ fontSize: "1.4em" }}
                >
                  <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
                    {[0, 1, 2, 3].includes(Number(user?.role)) ? (
                      <li class="nav-item">
                        <a class="nav-link" href="./verify">
                          Client
                        </a>
                      </li>
                    ) : null}
                    {[0, 1, 2, 3].includes(Number(user?.role)) ? (
                      <li class="nav-item">
                        <a class="nav-link" href="./encounters">
                          Encounters
                        </a>
                      </li>
                    ) : null}
                    {[0, 1, 3].includes(Number(user?.role)) ? (
                      <li class="nav-item">
                        <a class="nav-link" href="./preauth">
                          PA Requests
                        </a>
                      </li>
                    ) : null}
                    {[0, 2, 3].includes(Number(user?.role)) ? (
                      <li class="nav-item">
                        <a class="nav-link" href="./claims">
                          Claims
                        </a>
                      </li>
                    ) : null}
                    {[0, 2, 3].includes(Number(user?.role)) ? (
                      <li class="nav-item">
                        <a class="nav-link" href="./costing">
                          Costing
                        </a>
                      </li>
                    ) : null}
                    {[0, 4].includes(Number(user?.role)) ? (
                      <li class="nav-item">
                        <a class="nav-link" href="./dash">
                          IT Dashboard
                        </a>
                      </li>
                    ) : null}
                  </ul>

                  <div class="d-flex">
                    {user ? (
                      <Space wrap>
                        <Dropdown.Button
                          onClick={handleButtonClick}
                          overlay={menu}
                          icon={<UserOutlined />}
                        >
                          {user?.name}
                        </Dropdown.Button>
                      </Space>
                    ) : (
                      <a
                        class="btn btn-lg btn-outline-dark btn-rounded "
                        href="/login"
                        rel="noreferrer"
                      >
                        Sign In
                      </a>
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </nav>
      </header>
    </>
  );
};

export default HeaderHook;
